.threeDObjectCard .threeDObjectCardSelected {
  width: 100%;
  position: relative;
  padding: 8px;
}

.threeDObjectCardSelected {
  background: #4C969F !important;
}

.threeDObjectCard:hover {
  background: #3B3B3B;
}
