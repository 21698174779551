.dragging::before {
  content: '+';

  backdrop-filter: brightness(50%);

  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5vw;

  z-index: 100;
  pointer-events: none;
}

.blink {
  animation: blink-animation 1s linear alternate infinite;
  -webkit-animation: blink-animation 1s linear alternate infinite;
}

@keyframes blink-animation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes blink-animation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
