#color-popover {
  z-index: 100000 !important;
}

.ColorPicker-MuiBox-root {
  border: 1px solid white;
  background-color: #303030 !important;
}

.ColorPicker-MuiInputBase-root {
  color: white !important;
}

.ColorPicker-MuiButton-label {
  color: white !important;
}

.ColorPicker-MuiFormLabel-root {
  color: white !important;
}

.ColorPicker-MuiTypography-colorTextSecondary{
  color: white !important;
}