.swipe-line {
  --swipe-line-width: 6px;
  --swipe-line-bg-color: rgba(21, 32, 45, 0.8);
  --swipe-line-border-width: 1px;
  --swipe-line-border-color: white;
}

.swipe-line {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--swipe-line-bg-color);
  box-sizing: border-box;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: -1;
}

.swipe-line.swipe-horizontal {
  width: 100%;
  height: var(--swipe-line-width);
  border-top: var(--swipe-line-border-width) solid var(--swipe-line-border-color);
  border-bottom: var(--swipe-line-border-width) solid var(--swipe-line-border-color);
}

.swipe-line.swipe-vertical {
  width: var(--swipe-line-width);
  height: 100%;
  border-left: var(--swipe-line-border-width) solid var(--swipe-line-border-color);
  border-right: var(--swipe-line-border-width) solid var(--swipe-line-border-color);
}

.left-label {
  position: absolute;
  top: 5px;
  right: 10px;
  background-color: black;
  border-radius: 4px;
}

.right-label {
  position: absolute;
  top: 5px;
  left: 10px;
  background-color: black;
  border-radius: 4px;
}

.top-label {
  position: absolute;
  bottom: 10px;
  right: 5px;
  background-color: black;
  border-radius: 4px;
}

.bottom-label {
  position: absolute;
  top: 10px;
  right: 5px;
  background-color: black;
  border-radius: 4px;
}