.overview-map-panel {
  width: 320px;
  pointer-events: auto;
  top: 10px;
  left: 10px;
  position: absolute;
}

.overview-map {
  width: 100%;
  height: 280px;
}

.lock-container {
  position: absolute;
  top: 50px;
  left: 285px;
  width: 40px;
  height: 40px;
  z-index: 3;
  background-color: transparent;
  border: 0;
  font-size: 1.75em;
  padding: 2px 4px;
}
