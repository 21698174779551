.wms-info-label{
  background: black;
  border-radius: 8px;
  border: 5px;
  border-color: #969696;
  border-style: solid;
}

.wms-info-label::after{
  content: "";
  border-top: 6px solid #969696;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  position: absolute;
  bottom: -3px;
  left: 40%;
}