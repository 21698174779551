.replayPanel {
  font-weight: bold;
  font-size: 12pt;
  background-color: transparent;
  width:550px;
  height:20vh;
  align-self: center;
  position: absolute;
  bottom: 20px;
}

#sliderBox {
  display: block;
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
}

.replayText {
  width: 20px;
}

.replayTopRight {
  position: absolute;
  right: 20px;
  top: 10px;
}

.replayRight {
  position: absolute;
  right: 20px;
}

.replayLeftTop {
  position: absolute;
  left: 20px;
  top: 10px;
}

#replaySlider {
  -webkit-appearance: none;
  height: 1px;
  border-radius: 5px;
  background: white;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin-right: 10px;
  margin-left: 10px;
  padding: 0;
  width: 550px;
  left: -77px;
}

input[type='range']:not([disabled])#replaySlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background: #98c23c;
}

input[type='range'][disabled]#replaySlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background: #cbcbcb;
}

/*firefox slider styling*/
input[type='range']:not([disabled])#replaySlider::-moz-range-thumb {
  -moz-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background: #98c23c;
  padding: 0;
}

input[type='range'][disabled]#replaySlider::-moz-range-thumb {
  -moz-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background: #cbcbcb;
  padding: 0;
}

input[type='range']:not([disabled])#replaySlider2::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background: #98c23c;
}

input[type='range'][disabled]#replaySlider2::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background: #cbcbcb;
}

/*firefox slider styling*/
input[type='range']:not([disabled])#replaySlider2::-moz-range-thumb {
  -moz-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background: #98c23c;
  padding: 0;
}

input[type='range'][disabled]#replaySlider2::-moz-range-thumb {
  -moz-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background: #cbcbcb;
  padding: 0;
}

.hoverable:hover {
  padding: 8px;
  border-radius: 5px;
  background-color: #292929;
}

#speedFactor {
  width: 100px;
  height: 5px;
  padding: 0 0;
  border: 0;
  left: 10px;
  background: transparent;
  color: white;
  font-weight: bold;
}
