.actionIcon {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    padding: 1px 4px;
    margin-left: 2px;
    background: none;
}

.actionIcon:disabled {
    cursor: default;
}