.spin {
  animation: spin-animation 15s linear infinite;
  -webkit-animation: spin-animation 15s linear infinite;
}

@keyframes spin-animation {
  from {
    transform: rotate(3600deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes spin-animation {
  from {
    transform: rotate(3600deg);
  }
  to {
    transform: rotate(0deg);
  }
}