.upwelling-sensors-label{
  background: black;
  border-radius: 8px;
  border: 5px;
  border-color: #969696;
  border-style: solid;
}

.upwelling-sensors-label::after{
  content: "";
  border-top: 12px solid #969696;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  position: absolute;
  bottom: -10px;
  left: 46%;
}