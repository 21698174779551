.card-preview {
  width: 268px;
  height: 184px;
  border-radius: 5px;
}

.play-overlay {
  position: absolute;
  top: 8px;
  width: 268px;
  height: 184px;
  border-radius: 5px;
  opacity: 0;
}

.play-overlay:hover {
  background-color: rgba(255, 255, 255, 50%);
  opacity: 100%;
}


